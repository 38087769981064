<template>
    <div style="height: 100%">
        <v-banner v-if="isSpaceAdmin && isDistributedInstance && currentSpaceType === spaceTypes.EDUCATION_SPACE && !isSpaceArchived" class="mb-5">
            <v-avatar slot="icon" color="white" size="30"><v-icon large color="info">info</v-icon></v-avatar>
            <span class="subtitle-1">
                You can recall files and apps distributed to all students or if you wish to make updates, then return to the Master instance and distribute the
                changed materials.
            </span>
            <template v-slot:actions>
                <v-btn @click="goToMaster" target="_blank" color="primary">Return to Master instance</v-btn>
            </template>
        </v-banner>
        <v-banner v-if="isSpaceArchived && isSpaceAdmin && !isDistributedInstance" class="mb-5">
            <v-avatar slot="icon" color="white" size="30"><v-icon large color="info">info</v-icon></v-avatar>
            <span class="subtitle-1">
                This space is archived. You may
                <router-link
                    :to="{
                        name: getCloneRoute,
                        oid: this.$route.oid,
                        query: {
                            sid: this.$route.params.sid
                        }
                    }">
                    create a new space
                </router-link>
                reusing the contents of the Master instance, download files or distribute files/tables/applications to other spaces.
            </span>
            <template v-slot:actions>
                <v-btn href="https://docs.nuvolos.cloud/user-guides/education-guides/archiving-your-course" elevation="0" target="_blank" color="primary">
                    Learn more
                </v-btn>
            </template>
        </v-banner>
        <div style="height: 100%">
            <TheSnapshotAppIframe />
            <TheSnapshotFileList :style="$route.name !== 'snapshot-files' ? { 'z-index': -1, opacity: 0, position: 'fixed', top: '0px', left: '0px' } : {}" />
            <v-container
                :style="
                    $route.name === 'snapshot-files' || $router.name === 'app-open'
                        ? { 'z-index': -1, opacity: 0, position: 'fixed', top: '0px', left: '0px' }
                        : {}
                "
                fluid>
                <router-view name="notifications"></router-view>
                <transition name="fadeonly" mode="out-in">
                    <keep-alive :include="['snapshot-tables']">
                        <router-view></router-view>
                    </keep-alive>
                </transition>
            </v-container>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { enumsData } from '@/mixins/enums'
import { breadcrumbs } from '@/mixins/breadcrumbs'
const TheSnapshotAppIframe = () => import('@/modules/snapshot/views/TheSnapshotAppIframe.vue')
const TheSnapshotFileList = () => import('@/modules/snapshot/views/TheSnapshotFileList.vue')

export default {
    mixins: [breadcrumbs, enumsData],
    components: {
        TheSnapshotAppIframe,
        TheSnapshotFileList
    },
    computed: {
        ...mapState('snapshotStore', ['fileAreaType', 'pathArray', 'filesFetching']),
        ...mapState('spaceStore', ['spaceInstances']),
        ...mapGetters('spaceStore', ['currentSpaceType', 'isSpaceAdmin', 'isSpaceArchived']),
        ...mapGetters('snapshotStore', ['currentSnapshotData', 'currentFileLocalPath', 'isDevelopment']),
        ...mapGetters('instanceStore', ['isDistributedInstance']),
        currentSnapshotLockMode() {
            return this.currentSnapshotData ? this.currentSnapshotData.lock_mode_name : null
        },
        showSnapshotRestorationAlert() {
            if (
                ['snapshot-files', 'snapshot-tables', 'snapshot-applications'].includes(this.$route.name) &&
                this.currentSnapshotLockMode === 'RESTORING' &&
                this.isDevelopment
            ) {
                return true
            }
            return false
        },
        getCloneRoute() {
            return this.newSpace.ROUTES_BY_TYPE[this.currentSpaceType]
        },
        masterInstance() {
            const masterInstance = this.spaceInstances.find(instance => instance.long_id === this.instanceFixedNames.MASTER_INSTANCE)
            return masterInstance
        }
    },
    methods: {
        showSnapshotRestoreDialog() {
            const userTasksRoute = this.$router.resolve({ name: 'user-tasks' }).href
            this.$store.dispatch('showGlobalDialog', {
                dialogTitle: 'Snapshot Restoration',
                dialogText: `A snapshot is being restored to this instance. You can work with your data once the restoration has been completed.<br><br> Task progress can be tracked <a style="text-decoration:none" href="${userTasksRoute}">here</a>.`,
                dialogAction: ['refreshInstanceSnapshots']
            })
        },
        goToMaster() {
            this.openInstance('master', this.masterInstance.iid, this.roleTypes.INSTANCE_EDITOR)
        }
    },
    mounted() {
        if (this.showSnapshotRestorationAlert) {
            this.showSnapshotRestoreDialog()
        }
    },
    watch: {
        showSnapshotRestorationAlert: function (nextVal, preVal) {
            if (nextVal) {
                this.showSnapshotRestoreDialog()
            } else if (!nextVal && preVal) {
                this.$store.dispatch('snapshotStore/fetchCurrentFiles', { id: this.$route.params.snid, route: this.$route, setFetchingStatus: true })
                this.$store.dispatch('snapshotStore/fetchCurrentTables', this.$route.params.snid)
                this.$store.dispatch('snapshotStore/fetchApplications', this.$route.params.snid)
                this.$store.dispatch('hideGlobalDialog')
            }
        },
        $route: {
            handler(to, from) {
                if (to && to.name === 'snapshot-files' && this.$store.fileAreaType !== to.params.fileArea) {
                    this.$store.dispatch('snapshotStore/setFolderType', to.params.fileArea)
                }
                if (
                    from !== undefined &&
                    to.params.snid &&
                    from.params.snid &&
                    from.name === 'snapshot-files' &&
                    to.name === 'snapshot-files' &&
                    from.params.localPath !== to.params.localPath &&
                    to.params.localPath !== undefined &&
                    to.params.localPath !== this.currentFileLocalPath
                ) {
                    this.$store.dispatch('snapshotStore/setPathArray', to.params.localPath.split('/'))
                }
                if (
                    from !== undefined &&
                    to.params.snid &&
                    from.params.snid &&
                    from.name === 'snapshot-files' &&
                    to.name === 'snapshot-files' &&
                    from.params.localPath !== to.params.localPath &&
                    to.params.localPath === undefined &&
                    to.params.fileArea === from.params.fileArea
                ) {
                    this.$store.dispatch('snapshotStore/setPathArray', [''])
                }
                if (
                    (to.params.snid !== undefined &&
                        from !== undefined &&
                        from.params.snid !== undefined &&
                        to.params.snid.toString() !== from.params.snid.toString()) ||
                    (to.params.snid === undefined && from !== undefined && from.params.snid !== undefined)
                ) {
                    this.$store.dispatch('snapshotStore/resetSnapshotState')
                }
                if (
                    !['handin', 'handback'].includes(to.name) &&
                    ((to.params.snid !== undefined && from === undefined) ||
                        (to.params.snid !== undefined && from !== undefined && to.params.snid.toString() !== from.params.snid.toString()))
                ) {
                    this.$store.dispatch('snapshotStore/fetchCurrentFiles', { id: to.params.snid, route: this.$route, setFetchingStatus: true })
                    this.$store.dispatch('snapshotStore/fetchCurrentTables', to.params.snid)
                    this.$store.dispatch('snapshotStore/fetchApplications', to.params.snid)
                    if (window.sessionStorage.getItem('stagedObjects')) {
                        this.$store.dispatch('snapshotStore/setStagedObjectsFromStore', to.params.snid.toString())
                    }
                }
                // Handle file path
                if (to.name === 'snapshot-files' && to.params.localPath !== undefined) {
                    if (!this.filesFetching) {
                        this.$store.dispatch('snapshotStore/setPathArray', to.params.localPath.split('/'))
                        this.$store.dispatch('snapshotStore/fetchCurrentFiles', { id: to.params.snid, route: this.$route, setFetchingStatus: true })
                    }
                } else if (to.name === 'snapshot-files' && to.params.localPath === undefined) {
                    this.$store.dispatch('snapshotStore/setPathArray', [''])
                    this.$store.dispatch('snapshotStore/fetchCurrentFiles', { id: to.params.snid, route: this.$route, setFetchingStatus: true })
                }
                if (from && from.params.aid && to.params.aid === undefined && to.params.snid) {
                    this.$store.dispatch('snapshotStore/fetchCurrentFiles', { id: to.params.snid, route: this.$route, setFetchingStatus: false })
                }
            },
            immediate: true
        },
        currentFileLocalPath: function (nextVal, preVal) {
            if (nextVal !== preVal && this.$route.params.localPath !== nextVal) {
                switch (this.$route.name) {
                    case 'handin':
                        this.$router.push({
                            name: 'handin',
                            params: {
                                hid: this.$route.params.hid,
                                localPath: nextVal
                            }
                        })
                        this.$store.dispatch('snapshotStore/fetchCurrentFiles', {
                            id: this.$route.params.hid,
                            route: this.$route,
                            setFetchingStatus: true
                        })
                        break
                    case 'handback':
                        this.$router.push({
                            name: 'handback',
                            params: {
                                hid: this.$route.params.hid,
                                localPath: nextVal
                            }
                        })
                        this.$store.dispatch('snapshotStore/fetchCurrentFiles', {
                            id: this.$route.params.hid,
                            route: this.$route,
                            setFetchingStatus: true
                        })
                        break
                    default:
                        this.$router.push({
                            name: 'snapshot-files',
                            params: {
                                oid: this.$route.params.oid,
                                sid: this.$route.params.sid,
                                iid: this.$route.params.iid,
                                snid: this.$route.params.snid,
                                localPath: nextVal,
                                fileArea: this.fileAreaType
                            }
                        })
                        this.$store.dispatch('snapshotStore/fetchCurrentFiles', { id: this.$route.params.snid, route: this.$route, setFetchingStatus: true })
                        break
                }
            }
        }
    }
}
</script>
